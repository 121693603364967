<template>
  <div>
    <b-modal
      id="addChecklistDictionaryModal"
      :title="$t('conf.settings.checklist-dictionary.popup.add-title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >
      <validation-observer ref="newChecklistDictionaryValidation">
        <form
          id="createChecklistDictionaryForm"
          @submit.prevent
        >

          <b-row>
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.settings.checklist-dictionary.value')"
                rules="required|min:3|max:255"
              >
                <label for="checklist-dictionary">
                  {{ $t('conf.settings.checklist-dictionary.value') }}
                </label>
                <b-form-input
                  id="checklist-dictionary"
                  v-model="newChecklistDictionaryForm.value"
                  type="text"
                  minlength="3"
                  maxlength="255"
                  :placeholder="$t('conf.settings.checklist-dictionary.value')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <b-form-checkbox
                v-model="newChecklistDictionaryForm.active"
                class="mb-1"
              >
                {{ $t('conf.settings.checklist-dictionary.active') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </form>
      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { parseRequestError } from '@/helpers/helpers'
import {
  BCol, BFormCheckbox, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BModal,
    BFormInput,
    BRow,
    BCol,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      newChecklistDictionaryForm: this.$store.state.settings.emptyChecklistDictionary,
    }
  },
  mounted() {
    this.$bvModal.show('addChecklistDictionaryModal')
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newChecklistDictionaryValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('settings/addChecklistDictionary', this.newChecklistDictionaryForm)
            .then(() => {
              this.$bvModal.hide('addChecklistDictionaryModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('conf.settings.checklist-dictionary.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
  },
}
</script>
